//import useThunderDownloads from "../hooks/useThunderDownloads"

const ThunderDownloads = (/*{ id }*/) => {
  /*
  const parts = id.split(":")
  const path = parts[1]
  const downloads = useThunderDownloads(path)
  */
  return <div style={{ height: "21px" }}></div> //<span>{downloads}</span>
}

export default ThunderDownloads
